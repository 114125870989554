import { useConst, useDisclosure } from "@chakra-ui/hooks";
import { Popover, PopoverArrow, PopoverCloseButton, PopoverContent } from "@chakra-ui/popover";
import { useRef } from "react";
import { GoTriangleDown, GoTriangleUp } from "react-icons/go";
import FocusLock from "react-focus-lock";
import { Select } from "@chakra-ui/select";
import { nanoid } from "nanoid";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";

const Form = ({ id, firstFieldRef, options, onSelect }) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    return (
        <div className="flex flex-col">
            <div>
                <label ref={firstFieldRef} htmlFor={`select_th_${id}`} className="text-sm font-light text-dark">
                    {t("select")}
                </label>
                <Select
                    className="capitalize"
                    bg="white"
                    size="sm"
                    rounded="none"
                    id={`select_th_${id}`}
                    onChange={(e) => onSelect(e.target.value)}
                    defaultValue=""
                >
                    <option value="">{t("all")}</option>
                    {options?.map((opt: any) => (
                        <option className="capitalize" key={opt.value} value={opt.value}>
                            {opt.label}
                        </option>
                    ))}
                </Select>
            </div>
        </div>
    );
};

const SelectTh = ({
    text,
    options = [],
    onSelect = () => {},
}: {
    text: string;
    options?: { label: string; value: string | number }[];
    onSelect?: (value: string) => any;
}) => {
    const { onOpen, onClose, isOpen } = useDisclosure();
    const firstFieldRef = useRef(null);
    const uid = useConst(() => nanoid(5));

    return (
        <Popover
            key={uid}
            isOpen={isOpen}
            initialFocusRef={firstFieldRef}
            onOpen={onOpen}
            onClose={onClose}
            placement="bottom"
            closeOnBlur={true}
            flip={true}
        >
            <button
                onClick={isOpen ? onClose : onOpen}
                className="flex items-center font-bold"
            >
                <span className="mr-1">{text}</span>
                {!isOpen ? <GoTriangleDown size={14} /> : <GoTriangleUp size={14} />}
            </button>
            <PopoverContent p={5} maxW="16rem" bg="gray.50">
                <FocusLock returnFocus persistentFocus={false}>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <Form id={uid} firstFieldRef={firstFieldRef} options={options} onSelect={onSelect} />
                </FocusLock>
            </PopoverContent>
        </Popover>
    );
};

export default SelectTh;
