import React, { useEffect, useRef, useState } from "react";
import { Box } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { replaceMenu } from "src/redux/drawerSlice";
import chatServiceIa from "./services/chat_service_ai";
import { HistoryChats } from "./HistoryChats";
import { TypingBar } from "./TypingBar";
import { makeStyles, shorthands } from "@fluentui/react-components";
import { EventSourcePolyfill } from 'event-source-polyfill';
import { ChatList } from "./ChatList";
import { useChatHistory } from "src/store/KlausChatState";
import { ChatMultiple28Regular } from "@fluentui/react-icons";

interface Message {
  ask: string;
  answer: string;
  metadata?: string[];
  subdata?: string;
  img?: string;
}

interface ChatHistory {
  id: number;
  primary: string;
  secondary: string;
  metadata: string[];
  image: string | null;
  subdata: string | null;
  isSender: boolean;
}

interface ChatData {
  folderId: string | null;
  hash: string | null;
  history: Message[];
  question?: string | null;
  id?: number;
}

interface ChatContainerProps {
  documentHash?: string | null;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  chatArea: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  messagesArea: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  messageList: {
    flexGrow: 1,
    overflowY: 'auto',
    ...shorthands.padding('80px', '0'),
  },
  typingBarArea: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 16px',
  },
});

const messageTemplate: ChatHistory = {
  id: 1,
  primary: "",
  secondary: "",
  metadata: [],
  image: null,
  subdata: null,
  isSender: true,
};

const KlausChatContainer: React.FC<ChatContainerProps> = ({ documentHash = null }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const listRef = useRef<HTMLDivElement | null>(null);
  const eventSourceRef = useRef<EventSourcePolyfill | null>(null);
  const historyChatState = useChatHistory((s) => s);
  const [chatsHistory, setChatsHistory] = useState<any>([]);
  const [messages, setMessages] = useState<ChatData>({ folderId: null, hash: documentHash, history: [] });
  const [formattedMessages, setFormattedMessages] = useState<{ history: ChatHistory[] }>({ history: [] });
  const [answering, setAnswering] = useState(false);
  const [historial, setHistorial] = useState(false);
  const folderId = historyChatState.idFolder;

  useEffect(() => {
    dispatch(replaceMenu({ component: 'HomeTemplate', props: { selected: 'chat' } }));
    loadChats();
    if (historyChatState.chatId === null) {
      newChat();
    } else {
      loadMessages(historyChatState.chatId);
    }
  }, [dispatch, documentHash]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }
  }, [formattedMessages]);

  const loadChats = async () => {
    if (folderId) {
      const res = await chatServiceIa.getChats(folderId);
      setChatsHistory(res.reverse());
    }

    if (documentHash != null) {
      const res = await chatServiceIa.getChatsByDocumentHash(documentHash);
      setChatsHistory(res.reverse());
    }
  };

  const adapterMessages = (messageHistory: Message[] = []) => {
    const data = messageHistory.filter((n) => n.ask).flatMap((message) => {
      const result: ChatHistory[] = [];
      if (message.ask) {
        result.push({
          ...messageTemplate,
          primary: 'YOU',
          secondary: message.ask,
          isSender: true,
        });
      }
      if (message.answer) {
        result.push({
          ...messageTemplate,
          primary: 'BOT',
          secondary: message.answer,
          metadata: message.metadata || [],
          subdata: message.subdata || null,
          image: message.img || null,
          isSender: false,
        });
      }
      return result;
    });

    setFormattedMessages({ history: data });
  };

  const loadMessages = async (chatId: string) => {
    historyChatState.setChatId(chatId);
    const messages = await chatServiceIa.loadChat(chatId);
    setMessages(messages);
    adapterMessages(messages.history);
  };

  const sendMessage = (message: string) => {
    setAnswering(true);

    setMessages((prevMessages) => {
      const updatedHistory = [...prevMessages.history, { ask: message, answer: "-1" }];
      adapterMessages(updatedHistory);
      return { ...prevMessages, history: updatedHistory };
    });

    let msj = { ...messages, history: [], question: null };

    if (eventSourceRef.current) {
      eventSourceRef.current.close();
    }

    const authUser = JSON.parse(sessionStorage.getItem("authUser"));
    const chatString = encodeURIComponent(JSON.stringify({ ...msj, ask: message }));

    eventSourceRef.current = new EventSourcePolyfill(
      `${process.env.REACT_APP_API_AI}/api/v1/ai/chat/stream?chat=${chatString}`,
      {
        headers: { Authorization: `Bearer ${authUser.state.accessToken}` },
      }
    );

    eventSourceRef.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      historyChatState.setChatId(data.id);
      setMessages((prevMessages) => {
        const updatedHistory = [...prevMessages.history];
        const lastMessage = updatedHistory[updatedHistory.length - 1];
        lastMessage.answer = data.question.answer === "" ? "-1" : data.question.answer;
        lastMessage.metadata = data.question.metadata;
        lastMessage.img = data.question.img;
        lastMessage.subdata = data.question.subdata;
        adapterMessages(updatedHistory);
        return { ...prevMessages, id: data.id, history: updatedHistory };
      });
    };

    eventSourceRef.current.onerror = (error) => {
      setAnswering(false);
      if (msj.id === undefined) {
        loadChats();
      }
      console.error("Error en la conexión EventSource:", error);
      eventSourceRef.current?.close();
    };
  };

  const newChat = () => {
    const data = { folderId, hash: documentHash, history: [] };
    setMessages(data);
    adapterMessages(data.history);
  };

  const handleSeletedDocument = async (data: any, metadata: string[]) => {
    return null;
  };

  return (
    <Box className={styles.container}>
      <Box className={styles.chatArea}>
        <Box className={styles.messagesArea} p={2}>
          <Box ref={listRef} className={styles.messageList}>
            <HistoryChats
              messages={formattedMessages.history}
              selectedDocument={handleSeletedDocument}
            />
          </Box>
          <Box className={styles.typingBarArea}>
            <TypingBar isAnswering={answering} sendMessage={sendMessage} />
            <ChatMultiple28Regular onClick={() => setHistorial(!historial)} style={{cursor: 'pointer', justifyContent: 'space-between'}}/>
          </Box>
        </Box>
      </Box>
      {historial && (<ChatList historyChats={chatsHistory} loadMessages={loadMessages} newChat={newChat} isOpen={historial} />)}
    </Box>
  );
};

export default KlausChatContainer;
