import React from "react";
import {
  Button,
  TabList,
  Text,
  makeStyles,
  shorthands,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerHeaderTitle,
  Card,
  CardHeader,
} from "@fluentui/react-components";
import { Box } from "@chakra-ui/react";

const useStyles = makeStyles({
  container: {
    flexDirection: "column",
    height: "100%",
    overflowY: "auto",
    ...shorthands.gap("8px"),
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  newChatButton: {
    ...shorthands.margin("0", "0", "8px", "0"),
  },
  tabList: {
    overflow: 'auto',
    ...shorthands.gap("4px"),
  },
  tab: {
    maxWidth: "100%",
    ...shorthands.overflow("hidden"),
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  card: {
    marginBottom: "8px",
    padding: "12px",
    borderRadius: '8px',
    boxShadow: "4pxrgba(0, 0, 0, 0.1)",
    backgroundColor: '#F1F7FF',
  },
  drawerBody: {
    flex: 1, // Ajusta el DrawerBody para que tome todo el espacio disponible
    overflowY: "auto", // Habilita el scroll
  },
  drawer: {
    display: "flex",
    flexDirection: "column",
    height: "auto", // Asegura que el Drawer ocupe toda la altura de la ventana
  },
});

interface ChatListProps {
  historyChats: Array<{ id: string; ask: string }>;
  loadMessages: (chatId: string) => void;
  newChat: () => void;
  isOpen: boolean;
}

export const ChatList: React.FC<ChatListProps> = ({ historyChats = [], loadMessages, newChat, isOpen }) => {
  const styles = useStyles();

  return (
    <Drawer open={isOpen} position="start" separator type="inline" className={styles.drawer}>
      <DrawerHeader>
        <DrawerHeaderTitle style={{justifyContent: 'center'}}>
          Historial
        </DrawerHeaderTitle>
      </DrawerHeader>

      {/* Cuerpo del Drawer con scroll */}
      <DrawerBody className={styles.drawerBody}>
        {/* Lista de chats envueltos en tarjetas */}
        <TabList vertical className={styles.tabList}>
          {historyChats.map((chat) => (
            <Card key={chat.id} className={styles.card} onClick={() => loadMessages(chat.id)}>
              <CardHeader
                header={
                  <Text
                    style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", cursor: "pointer" }}
                  >
                    {chat.ask}
                  </Text>
                }
              />
            </Card>
          ))}
        </TabList>
      </DrawerBody>

      <DrawerFooter>
        <Button appearance="primary" onClick={newChat} className={styles.newChatButton}>
          Nuevo Chat
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};
