import { useEffect, useState } from "react";
import {
    Button,
    FormControl,
    FormErrorMessage,
    HStack,
    Input,
    InputGroup,
    Tag,
    TagCloseButton,
    TagLabel,
    TagRightIcon,
    useBoolean,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import CommonModal from "../common/CommonModal";
import IconHelp from "src/assets/bidding-icons/HelpIcon";
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    PopoverAnchor,
} from "@chakra-ui/react";
import useBiddingState from "src/store/biddings/biddingState";
import useCustomToast from "../../hooks/useCustomToast";
import { IoMdAdd } from "react-icons/io";
import { IoMdClose } from "react-icons/io";
import PowerBiImg from "../../assets/images/PowerBi.png";
import useBiddingTypesState from "src/store/biddings/biddingTypesState";
import useAdministrationState from "src/store/administrationState";
import { useFormik } from "formik";
import * as Yup from "yup";

const PopoverHelp = () => {
    const [isOpen, setIsOpen] = useState(false);

    const togglePopover = () => setIsOpen(!isOpen);

    return (
        <div>
            <Button onClick={togglePopover}>?</Button> {/* Botón para abrir el Popover */}
            <Popover isOpen={isOpen} onClose={() => setIsOpen(false)}>
                <PopoverContent color="white" bg="blue.800" borderColor="blue.800">
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader>{"Para llenar este campo, sigue estos pasos:"}</PopoverHeader>
                    <PopoverBody>Todo valor de tipo texto debe ir dentro de comillas simples, ejemplo: 'Mi nuevo formato'.</PopoverBody>
                    <PopoverBody>La 'fecha de publicación' debe seguir el formato dd MM yyyy.</PopoverBody>
                </PopoverContent>
            </Popover>
        </div>
    );
};

interface FormFields {
    name: string;
}

const AddPropertiesModal = ({
    onCancel,
    onSave,
    name,
    label,
    isOpen,
    superCategority,
    formatNewProccessName,
    handleSelectField,
    selectedFields,
    data,
}: {
    onCancel: () => any;
    onSave: (superCategority: string, formatNewProccessName: string) => any;
    isOpen: boolean;
    name?: string;
    label: string;
    superCategority?: string;
    formatNewProccessName?: string;
    handleSelectField?: any;
    selectedFields?: any;
    data?: any;
}) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const [invalidName, setInvalidName] = useBoolean(false);
    const [typePattern, setTypePattern] = useState("");
    const [labelSuperCategory, setLabelSuperCategory] = useState("");
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [exampleFormat, setExampleFormat] = useState("");
    const { transformStringPatternForProperties } = useBiddingState();
    const toast = useCustomToast();
    const [oldName, setOldName] = useState(name);
    const {
        getBiddingTypesByCompany,
        biddingTypesByCompany: { data: biddingTypes },
    } = useBiddingTypesState();
    const { getAssociatedCompanies, associatedCompanies } = useAdministrationState();

    const formik = useFormik<FormFields>({
        initialValues: {
            name: data.powerBiUrl,
        },
        onSubmit: handleCreate,
        validateOnBlur: true,
        validateOnChange: false,
        validationSchema: Yup.object({
            name: Yup.string().required(t("requiredField")),
        }),
    });

    async function handleCreate(values: FormFields) {
        data.addUrlPowerBi(values.name);
    }

    useEffect(() => {
        // revisar porque se usaban los gets
     //   getBiddingTypesByCompany().catch((err) => {
      //      toast.error(t("listingBiddingTypesError"));
     //   });
     //   getAssociatedCompanies();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setTypePattern(formatNewProccessName);
        setLabelSuperCategory(superCategority);
        setOldName(name);
    }, [superCategority, formatNewProccessName, name]);

    function handleSaveType() {
        if (!typePattern?.length) {
            return setInvalidName.on();
        } else {
            setInvalidName.off();
        }

        onSave(typePattern, labelSuperCategory);
        setTypePattern("");
        setLabelSuperCategory("");
    }

    async function onGetFormat() {
        let body = {
            specificDate: "",
            stringWithPattern: typePattern,
        };

        const getTypePattern = await transformStringPatternForProperties(body, false);
        if (getTypePattern) {
            toast.success("Formato generado");
            setExampleFormat(getTypePattern["respuesta"]);
        } else {
            toast.error("Error al generar formato");
        }
    }

    useEffect(() => {
        if (isInputFocused) {
            onGetFormat();
        }
    }, [isInputFocused && typePattern]);

    return (
        <CommonModal size="xl" isOpen={isOpen} onClose={onCancel}>
            <div className="w-full flex flex-col space-y-6 py-4">
                <h2 className="title-3">{label}</h2>
                <FormControl flex={1} isInvalid={invalidName}>
                    <div className="flex items-center pb-2">
                        <span className="mr-4">
                            <label htmlFor="type_name" className="text-sm leading-8">
                                {t("formatNameInProcess")}
                            </label>
                        </span>
                        <span>
                            <PopoverHelp />
                        </span>
                    </div>
                    <InputGroup borderColor="gray.400">
                        <Input
                            id="type_label"
                            type="text"
                            value={typePattern}
                            rounded="none"
                            placeholder={t("nameFormat")}
                            onChange={(e) => setTypePattern(e.target.value)}
                            onBlur={() => setIsInputFocused(false)}
                        />
                    </InputGroup>
                    <label htmlFor="type_name" className="text-sm leading-6 pl-2 text-[#2E54B8]">
                        {"Ejemplo de formato:"} {exampleFormat}
                    </label>
                </FormControl>
                <FormControl flex={1}>
                    <label htmlFor="type_category" className="text-sm leading-8">
                        {t("superCategoryLabel")}
                    </label>
                    <InputGroup borderColor="gray.400">
                        <Input
                            id="type_category"
                            type="text"
                            value={labelSuperCategory}
                            rounded="none"
                            placeholder={t("label")}
                            onChange={(e) => setLabelSuperCategory(e.target.value)}
                            onFocus={() => setIsInputFocused(true)}
                            onBlur={() => setIsInputFocused(false)}
                        />
                    </InputGroup>
                </FormControl>
                <div>
                    <h3 className="text-sm mb-2">{t("addLabels")}:</h3>
                    <HStack spacing={4}>
                        <Tag
                            size="lg"
                            borderRadius="full"
                            colorScheme={"inherit"}
                            onClick={() => handleSelectField("endDateField")}
                            className={`cursor-pointer ${
                                !selectedFields.endDateField ? "border text-[#0327e2] border-[#0327e2]" : "bg-[#0327e2] text-gray-100"
                            }`}
                        >
                            <TagLabel>Fecha fin</TagLabel>
                            <TagRightIcon as={!selectedFields.endDateField ? IoMdAdd : IoMdClose} />
                        </Tag>
                        <Tag
                            size="lg"
                            borderRadius="full"
                            colorScheme={"inherit"}
                            onClick={() => handleSelectField("powerBiURLField")}
                            className={`cursor-pointer ${
                                !selectedFields.powerBiURLField ? "border text-[#0327e2] border-[#0327e2]" : "bg-[#0327e2] text-gray-100"
                            }`}
                        >
                            <TagLabel>URL Power BI</TagLabel>
                            <TagRightIcon as={!selectedFields.powerBiURLField ? IoMdAdd : IoMdClose} />
                        </Tag>
                        <Tag
                            size="lg"
                            borderRadius="full"
                            colorScheme={"inherit"}
                            onClick={() => handleSelectField("hasExpedienteUnico")}
                            className={`cursor-pointer ${
                                selectedFields.hasExpedienteUnico ? "bg-[#0327e2] text-gray-100" :  "border text-[#0327e2] border-[#0327e2]" 
                            }`}
                        >
                            <TagLabel>Expediente de datos</TagLabel>
                            <TagRightIcon as={selectedFields.hasExpedienteUnico ? IoMdAdd : IoMdClose} />
                        </Tag>
                    </HStack>
                </div>
                <div>
                    <HStack spacing={4}>
                     
                        <Tag
                            size="lg"
                            borderRadius="full"
                            colorScheme={"inherit"}
                            onClick={() => handleSelectField("hasUrlGeneradores")}
                            className={`cursor-pointer ${
                                !selectedFields.hasUrlGeneradores ? "border text-[#0327e2] border-[#0327e2]" : "bg-[#0327e2] text-gray-100"
                            }`}
                        >
                            <TagLabel>URL Generadores</TagLabel>
                            <TagRightIcon as={!selectedFields.hasUrlGeneradores ? IoMdAdd : IoMdClose} />
                        </Tag>

                    </HStack>
                </div>
                {/* {selectedFields?.powerBiURLField && (
                    <div>
                        <form
                            className="w-full flex flex-wrap justify-between items-center gap-2 max-h-[75vh] overflow-y-auto px-2"
                            // onSubmit={formik.handleSubmit}
                        >
                            <FormControl
                            // isInvalid={!!formik.errors.name}
                            >
                                <label htmlFor="name" className="text-sm leading-8">
                                    {"URL POWER BI"}
                                </label>

                                <InputGroup borderColor="gray.400">
                                    <img src={PowerBiImg} style={{ width: "70px", height: "70px", paddingBottom: "1.6rem" }}></img>

                                    <Input
                                        id="name"
                                        name="name"
                                        type="text"
                                        rounded="none"
                                        onChange={formik.handleChange}
                                        value={formik.values.name}
                                    />
                                </InputGroup>
                                <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
                            </FormControl>
                            <div className="mt-8 pb-2 w-full text-center">
                                <Button type="submit" rounded="full" size="md" fontWeight="medium" px={"2rem"} isLoading={false}>
                                    {t("add")}
                                </Button>
                            </div>
                        </form>
                    </div>
                )} */}

                <div className="mt-8 pb-2 w-full text-center">
                    <Button
                        type="submit"
                        rounded="full"
                        size="md"
                        onClick={handleSaveType}
                        fontWeight="medium"
                        px={"2rem"}
                        isLoading={false}
                    >
                        {t("save")}
                    </Button>
                </div>
            </div>
        </CommonModal>
    );
};

export default AddPropertiesModal;
