import { Box, Button, ButtonGroup, IconButton, Stack, useBoolean } from "@chakra-ui/react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineUpload } from "react-icons/ai";
import useCustomToast from "src/hooks/useCustomToast";
import { TranslationKeys } from "src/i18n/en";
import { BiddingRequirement, BiddingRequirementDocument } from "src/models/biddings/Bidding.models";
import useBiddingState from "src/store/biddings/biddingState";
import useModalState, { confirmAlert } from "src/store/modalsState";
import useVaultState from "src/store/vaultState";
import SlicedText from "../common/SlicedText";
import BiddingDocumentCard from "./BiddingDocumentCard";
import PdfViewerFull from "./BiddingPdfViewer";
import { Tooltip } from "@chakra-ui/react";
import TruncatedButton from "./TruncatedButton";
import { IoMdSearch } from "react-icons/io";
import { FaFileSignature } from "react-icons/fa";
import { extensionIcons } from "../../Utils/iconsSelector";
import { RiFileSettingsFill } from "react-icons/ri";
import { Switch } from "@chakra-ui/react";
import { IoMdInfinite } from "react-icons/io";
import { FaStar } from "react-icons/fa";
import { IoIosInfinite } from "react-icons/io";
import { FaAsterisk } from "react-icons/fa";
import { BsTable } from "react-icons/bs";
import useGlobalState from "src/store/globalState";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { BsQuestionCircleFill } from 'react-icons/bs';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai'; // Importa los íconos necesarios de React Icons

import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    PopoverAnchor,
} from '@chakra-ui/react'
import React from "react";
import { useAxRoadmapStore } from "src/store/axRoadmap.store";
import RoadMapViewModal from "../common/modals/biddings/RoadMapViewModal";
import { b64toBlob, fixEncoding } from "src/lib/utils";
import { useAxCalcStore } from "src/store/axCalc.store";

const RequirementCard = ({
    requirement,
    selectedMonth,
    selectedYear,
    afterUploadDocument,
    disabled,
    selectedBiddingId,
}: {
    requirement: BiddingRequirement;
    selectedMonth: number;
    selectedYear: number;
    afterUploadDocument: any;
    disabled?: boolean;
    selectedBiddingId?: any;
}) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const toast = useCustomToast();
    const { setUploadBiddingReqDocModal, setUploadBiddingReqAxCalcModal, setHandleBiddingSCMModal } = useModalState();
    const selectedBidding = useBiddingState((s) => s.selectedBidding);
    const getBidding = useBiddingState((s) => s.getBidding);
    const { desassociateSmartContractToBiddingReq, removeBiddingReqDocumentOrAnnex, enableBiddingReq } = useBiddingState();
    const downloadDocument = useVaultState((s) => s.downloadDocument);
    const getDocument = useVaultState((s) => s.getDocument)
    const { setTasksRoadMapselect, setOnlyRead } = useAxRoadmapStore();

    const [selectedDoc, setSelectedDoc] = useState<BiddingRequirementDocument>(null);
    const [showAnnexes, setShowAnnexes] = useBoolean(false);
    const [showRoadmap, setShowRoadmap] = useState(false);
    const showDocuments = !showAnnexes;
    const isSCMType = Boolean(requirement?.documentType?.find((d) => d.name === "SCM"));
    const isPDFType = Boolean(requirement?.signStatus === "SI");
    const isReviewFlowType = Boolean(requirement?.reviewStatus === "SI");

    const isPDFForSign = Boolean(requirement?.documentType?.find((d) => d.name === "PDF con Firma Digital"));
    const [enabledSwitch, setEnabledSwitch] = useState(false);
    const additionalCompanyInfo = useGlobalState((s) => s.additionalCompanyInfo);
    const navigate = useNavigate();
    const location = useLocation();

    function handleUploadDocument(type: "document" | "annex", edit?: any, docId?: string) {
        const body = {
            show: true,
            data: {
                type,
                requirement: requirement,
                biddingId: selectedBidding?.id,
                month: selectedMonth,
                year: selectedYear,
                afterUpload: () => {
                    getBidding(selectedBidding?.id);
                    afterUploadDocument(requirement);
                },
            },
        }
        if (type === 'document' && (requirement.documentType[0].name === 'Ax Calc' || requirement.documentType[0].name === 'Ax RoadMap')) {
            useAxRoadmapStore.getState().reset();
            const bodySet = {
                ...body,
                data: {
                    ...body.data,
                    edit: edit,
                    docId: docId
                }
            }
            setUploadBiddingReqAxCalcModal(bodySet);
        } else {
            setUploadBiddingReqDocModal(body);
        }

    }

    function handleSCMTypeOnClick(e, requirement) {
        e.preventDefault();

        if (requirement.alwaysNew === true) {
            const assignamentState = {
                requirement,
                biddingId: selectedBidding?.id,
                biddinName: selectedBidding.name,
                reqId: requirement.uuid,
                month: selectedMonth,
                year: selectedYear,
                expedienteUnico: selectedBidding?.expedienteUnico
            };

            navigate(`/contracts/create?template=${requirement?.template?.id}`, { state: { assignToBidding: true, assignamentState } });

        } else {
            setHandleBiddingSCMModal({
                show: true,
                data: {
                    requirement,
                    biddingId: selectedBidding?.id,
                    biddingName: selectedBidding.name,
                    month: selectedMonth,
                    year: selectedYear,
                    expedienteUnico: selectedBidding?.expedienteUnico,
                    afterUpload: () => {
                        getBidding(selectedBidding?.id);
                        afterUploadDocument(requirement);
                    },
                },
            });
        }
    }

    async function handleDownload(docFileId: string, name: string) {
        const res = await downloadDocument(docFileId, name);
        if (!res) {
            toast.error("Ocurrió un error al descargar el documento");
        }
    }

    async function handleGetFile(docFileId: string) {
        const res = await getDocument(docFileId);
        if (!res) {
            toast.error("Ocurrió un error al descargar el documento");
        }
        return res;
    }

    function handleOpenDocClick(doc: BiddingRequirementDocument) {
        const extension = doc.name.split(".").pop();
        if (extension === "pdf") {
            setSelectedDoc(doc);
        } else if (requirement.documentType[0].name === 'Ax RoadMap' || requirement.documentType[0].name === 'Ax Calc') {

            handleGetFile(doc.fileId).then(e => {
                if (requirement.documentType[0].name === 'Ax RoadMap') {
                    const jsonStr = atob(e.file); // Decodificar base64 a string JSON
                    const jsonObject = JSON.parse(jsonStr); // Parsear string JSON a objeto
                    const tasksDateParsed = jsonObject.map((e: any) => ({
                        ...e,
                        start: new Date(e.start),
                        end: new Date(e.end),
                        name: fixEncoding(e.name)
                    }))
                    setTasksRoadMapselect(tasksDateParsed);
                } else {
                    const fileBlob: Blob = b64toBlob(e.file, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
                    const file = new File([fileBlob], e.name, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    useAxCalcStore.getState().setSelectedFileTable(file!);
                }

            });
            //setShowRoadmap(true)
            handleUploadDocument("document", true, doc.uuid)
        }
        else {
            handleDownload(doc.fileId, doc.name);
        }
    }

    async function handleDeleteDocClick(doc: BiddingRequirementDocument, isContract: boolean) {
        if (isContract) {
            const done = await desassociateSmartContractToBiddingReq(doc.uuid, selectedBidding?.id, requirement.uuid);
            if (done) {
                toast.success("Contrato desasociado correctamente");
                getBidding(selectedBidding?.id);
                afterUploadDocument(requirement);
            } else {
                toast.error("Ocurrió un error al desasociar el contrato");
            }
        } else {
            const done = await removeBiddingReqDocumentOrAnnex(selectedBidding?.id, requirement.uuid, doc.uuid);

            if (done) {
                toast.success("Documento eliminado correctamente");
                getBidding(selectedBidding?.id);
                afterUploadDocument(requirement);
            }
        }
    }

    async function onDelete(requirement: any) {
        let enabled = !enabledSwitch === true ? "habilitado" : "deshabilitado";
        let enabledValue = requirement.enable === true ? true : false;
        const enabledReq = await enableBiddingReq(selectedBiddingId, requirement.uuid);
        if (enabledReq?.success) {
            toast.success(`Requerimiento ${enabled} con éxito`);
            setEnabledSwitch(!enabledSwitch);
            getBidding(selectedBidding?.id);

            // getProfilesBiddingById(biddingId);
            // getBiddingNotificacionCompany(biddingId);
        } else {
            toast.error(`Error al ${enabled} el requerimiento`);
        }
    }

    function handleEnableRequirement(requiriment: any) {
        let enabled = enabledSwitch === true ? "Deshabilitar" : "Habilitar";

        confirmAlert.show({
            title: `${enabled} requerimiento`,
            msg: `¿Estas seguro de ${enabled.toLowerCase()} el requerimiento?`,
            severity: "warning",
            onConfirm: () => onDelete(requirement),
        });
    }

    const matchedDocsByDate = useMemo(() => {
        return requirement?.documents?.filter((doc) => doc.month === selectedMonth && doc.year === selectedYear);
    }, [selectedMonth, selectedYear, requirement]);

    const matchedAnnexsByDate = useMemo(() => {
        return requirement?.annexe?.documents?.filter((doc) => doc.month === selectedMonth && doc.year === selectedYear);
    }, [selectedMonth, selectedYear, requirement]);

    const hasDocuments = matchedDocsByDate?.length > 0;
    const hasAnnexes = matchedAnnexsByDate?.length > 0;
    const extensionsAllowed = requirement?.documentType?.map((dt) => dt.extension);
    const documentType = requirement?.documentType?.map((dt) => dt);

    const disabledReq = requirement.name === " de"; // Cambia 'Nombre Específico' al nombre que desees

    const buttonStyles = {
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.5)',
        width: '5px',
        height: '5px'
    };

    function getInfo(requiriment: any) {
        if (requirement?.minUploadable === -1) {
            return <FaAsterisk size={12} className="ml-1" />;
        }

        // if (requirement?.maxUploadable === -1) {
        //     return <IoIosInfinite size={20} className="ml-0.5" />;
        // }

        return requirement?.minUploadable;
    }

    useEffect(() => {
        if (requirement) {
            setEnabledSwitch(requirement?.enable);
        }
    }, [requirement]);


    const WalkthroughPopover = ({ requerimentIdList }) => {
        const initialFocusRef = useRef();
        const [data, setData] = useState([]);
        const [isOpen, setIsOpen] = useState(false);
    
        useEffect(() => {
            if (requerimentIdList && typeof requerimentIdList === "string") {
                const requerimentNames = requerimentIdList.split("||");
                setData(requerimentNames);
            } else {
                setData([]);
            }
        }, [requerimentIdList]);
    
        if (!requerimentIdList || typeof requerimentIdList !== "string") {
            return <p>No hay elementos disponibles</p>;
        }
    
        const namesArray = requerimentIdList.split("||");
    
        const togglePopover = () => setIsOpen(!isOpen);
    
        return (
            <div>
                {/* IconButton controla manualmente la apertura del Popover */}
                <IconButton
                    size="sm"
                    rounded="full"
                    fontSize="xx-small"
                    px={4}
                    py={4}
                    style={buttonStyles}
                    aria-label="Add to friends"
                    icon={<BsQuestionCircleFill size={24} />}
                    onClick={togglePopover}
                />
                <Popover
                    initialFocusRef={initialFocusRef}
                    placement="bottom"
                    closeOnBlur={false}
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                >
                    <PopoverContent color="white" bg="blue.800" borderColor="blue.800">
                        <PopoverHeader pt={4} fontWeight="bold" border="0">
                            Documentos Asociados
                        </PopoverHeader>
                        <PopoverArrow bg="blue.800" />
                        <PopoverCloseButton />
                        <PopoverBody>
                            <div className="w-full">
                                <ul style={{ listStyleType: "none", padding: 0 }}>
                                    {namesArray.map((name, index) => (
                                        <li key={index} style={{ display: "flex", alignItems: "center" }}>
                                            <SlicedText text={name} maxLength={34} />
                                            {index % 2 === 0 ? (
                                                <AiOutlineCheck style={{ marginLeft: "0.5rem" }} />
                                            ) : (
                                                <AiOutlineClose style={{ marginLeft: "0.5rem" }} />
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
            </div>
        );
    };



    if (requirement.name !== "Plantilla Especial") {
        return (
            <>
                <div className="w-full min-w-[290px] border border-brand-500 flex flex-col">
                    <div
                        className={`${disabledReq || disabled || !enabledSwitch
                            ? "bg-slate-400 bg-opacity-75 flex flex-col justify-end items-center gap-2 p-4"
                            : "bg-slate-200 flex flex-col justify-end items-center gap-2 p-4"
                            }`}
                    >
                        <h4 className="text-xl font-semibold text-brand-600/80 text-center">
                            <SlicedText text={requirement.name} maxLength={32} />
                        </h4>
                        <div>

                            {requirement.requerimentIdList && requirement.requerimentIdList.length > 0 ? (
                                <WalkthroughPopover requerimentIdList={requirement.requerimentIdList[0]} />
                            ) : (
                                <p>{""}</p>
                            )}


                        </div>
                        <div className="w-full flex justify-between items-end mt-2 min-h-[68px]">
                            <div className="text-xs text-brand-500 flex mr-2 overflow-x-auto h-full items-end">
                                {!showAnnexes
                                    ? extensionsAllowed?.length
                                        ? extensionsAllowed.map((extension, index) => (
                                            <Tooltip
                                                key={index}
                                                label={`Tipo de documento: ${isSCMType ? "Documento dinámico" : extension.toLocaleUpperCase()
                                                    }`}
                                                fontSize="12px"
                                            >
                                                <button>
                                                    {isSCMType ? (
                                                        <RiFileSettingsFill size="25" className="ml-2" color="#0327e2" />
                                                    ) : extension === "json" && requirement?.documentType.filter(e => e.extension === 'json')[0].name !== 'Ax RoadMap' ? (
                                                        <BsTable className="mr-2" size="15" color="#001fb1" />
                                                    ) : (
                                                        <img
                                                            key={index}
                                                            src={extensionIcons[extension.toLowerCase()] || ""}
                                                            alt={extension}
                                                            className="icono-clase-css ml-2 w-8 h-8"
                                                        />
                                                    )}
                                                </button>
                                            </Tooltip>
                                        ))
                                        : "---"
                                    : "---"}
                            </div>
                            {disabledReq || disabled || !enabledSwitch ? (
                                <>
                                    <div className="flex flex-col items-end pr-0">
                                        <div className="flex p-2 pr-0">
                                            <div className="flex-1 cursor-help">

                                                <Tooltip
                                                    fontSize="12px"
                                                    label={
                                                        <div>
                                                            <p>Minimo de documentos a subir: {requirement?.minUploadable === -1 ? "Opcional" : requirement?.minUploadable}</p>
                                                            <p>Maximo de documentos a subir: {requirement?.maxUploadable === -1 ? "Ilimitado" : requirement?.maxUploadable}</p>
                                                            <p>Requerimiento opcional: {requirement?.minUploadable === 1 ? "No" : "No"}</p>
                                                        </div>
                                                    }
                                                    maxW="lg" // Establecer un ancho máximo
                                                >
                                                    <span className="font-semibold text-brand-600/80 flex items-center">
                                                        {requirement?.documents?.length} /{getInfo(requirement)}
                                                    </span>
                                                </Tooltip>
                                            </div>
                                            <div className="ml-3">
                                                <Tooltip
                                                    label={`Documento está ${enabledSwitch === true ? "habilitado" : "deshabilitado"}`}
                                                    fontSize="12px"
                                                >
                                                    <Stack align="center" direction="row">
                                                        <Switch
                                                            size="md"
                                                            isChecked={enabledSwitch}
                                                            onChange={() => handleEnableRequirement(requirement)}
                                                        />
                                                    </Stack>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div className="mt-1">
                                            <Tooltip label="No puedes subir documentos" aria-label="A tooltip">
                                                <Button size="xs" fontSize="sm" fontWeight="medium" colorScheme={"teal"}>
                                                    Deshabilitado
                                                </Button>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </>
                            ) : showDocuments ? (
                                isSCMType ? (
                                    <>
                                        <div className="flex ml-auto ">
                                            <div className="h-full items-end">
                                                <Tooltip label="Este requerimiento contiene revision" fontSize="12px">
                                                    <button className="border p-1 rounded-md border-[#0327e2] mr-2">
                                                        <IoMdSearch size={20} color="#0327e2" />
                                                    </button>
                                                </Tooltip>
                                            </div>
                                            <div>
                                                {additionalCompanyInfo.isAmericaMovil === false &&
                                                    <Tooltip label="Este requerimiento contiene proceso de firma" fontSize="12px">
                                                        <button className="border p-1 rounded-md border-[#0327e2] mr-2">
                                                            <FaFileSignature size={20} color="#0327e2" />
                                                        </button>
                                                    </Tooltip>
                                                }
                                            </div>
                                        </div>

                                        <div>
                                            <div className="flex p-2 pr-0">
                                                <div className="flex-1 cursor-help">
                                                    <Tooltip
                                                        fontSize="12px"
                                                        label={
                                                            <span>
                                                                Minimo de documentos a subir :{" "}
                                                                {requirement?.minUploadable === -1 ? "Opcional" : requirement?.minUploadable}{" "}
                                                                <br />
                                                                Maximo de documentos a subir :{" "}
                                                                {requirement?.maxUploadable === -1
                                                                    ? "Ilimitado"
                                                                    : requirement?.maxUploadable}{" "}
                                                                <br />
                                                                Requerimiento opcional: {requirement.minUploadable === 1 ? "No" : "Sí"}
                                                            </span>
                                                        }
                                                    >
                                                        <span className="font-semibold text-brand-600/80 flex items-center">
                                                            {requirement?.documents?.length} /{getInfo(requirement)}
                                                        </span>
                                                    </Tooltip>
                                                </div>
                                                <div className="ml-3">
                                                    <Tooltip
                                                        label={`Documento está ${enabledSwitch === true ? "habilitado" : "deshabilitado"}`}
                                                        fontSize="12px"
                                                    >
                                                        <Stack align="center" direction="row">
                                                            {/*requirement.alwaysNew === false &&
                                                                <Switch
                                                                    size="md"
                                                                    isChecked={enabledSwitch}
                                                                    onChange={() => handleEnableRequirement(requirement)}
                                                                />
                                                                Validar si es necesario dejar disponible que de deshabilite,
                                                                Requerimiento de que no se pueda deshabilitar
                                                    */}   <Switch
                                                                size="md"
                                                                isChecked={enabledSwitch}
                                                                onChange={() => handleEnableRequirement(requirement)}
                                                            />
                                                        </Stack>
                                                    </Tooltip>
                                                </div>
                                            </div>

                                            <div className="mt-2">
                                                <SCMCaseButton
                                                    disabled={
                                                        requirement?.maxUploadable !== -1 &&
                                                        requirement?.minUploadable !== -1 &&
                                                        requirement?.documents?.length >= requirement?.maxUploadable
                                                    }
                                                    name={additionalCompanyInfo.isAmericaMovil ? "Capturar información" : "Seleccionar"}
                                                    onClick={(e) => handleSCMTypeOnClick(e, requirement)}
                                                />
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="flex ml-auto">
                                            <div className="h-full items-end">
                                                {isPDFForSign && (
                                                    <Tooltip label="Este requerimiento contiene proceso de firma" fontSize="12px">
                                                        <button className="border p-1 rounded-md border-[#0327e2] mr-2">
                                                            <FaFileSignature size={20} color="#0327e2" />
                                                        </button>
                                                    </Tooltip>
                                                )}
                                                {
                                                    isReviewFlowType && (
                                                        <Tooltip label="Este requerimiento contiene revision" fontSize="12px">
                                                            <button className="border p-1 rounded-md border-[#0327e2] mr-2">
                                                                <IoMdSearch size={20} color="#0327e2" />
                                                            </button>
                                                        </Tooltip>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div>
                                            <div className="flex p-2 pr-0">
                                                <div className="flex-1 cursor-help">
                                                    <Tooltip
                                                        fontSize="12px"
                                                        label={
                                                            <span>
                                                                Minimo de documentos a subir :{" "}
                                                                {requirement?.minUploadable === -1 ? "Opcional" : requirement?.minUploadable}{" "}
                                                                <br />
                                                                Maximo de documentos a subir :{" "}
                                                                {requirement?.maxUploadable === -1
                                                                    ? "Ilimitado"
                                                                    : requirement?.maxUploadable}{" "}
                                                                <br />
                                                                Requerimiento opcional: {requirement.minUploadable === 1 ? "No" : "Si"}
                                                            </span>
                                                        }
                                                    >
                                                        <span className="font-semibold text-brand-600/80 flex items-center">
                                                            {" "}
                                                            {requirement?.documents?.length} / {getInfo(requirement)}
                                                        </span>
                                                    </Tooltip>
                                                </div>
                                                <div className="ml-3">
                                                    <Tooltip
                                                        label={`Documento está ${enabledSwitch === true ? "habilitado" : "deshabilitado"}`}
                                                        fontSize="12px"
                                                    >
                                                        <Stack align="center" direction="row">
                                                            <Switch
                                                                size="md"
                                                                isChecked={enabledSwitch}
                                                                onChange={() => handleEnableRequirement(requirement)}
                                                            />
                                                        </Stack>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                            <div className="mt-1 flex justify-end">
                                                {/**  disabled={
                                        requirement?.maxUploadable !== -1 &&
                                        requirement?.minUploadable !== -1 &&
                                        requirement?.documents?.length >= requirement?.maxUploadable
                                     */}
                                                <TruncatedButton
                                                    disabled={
                                                        requirement?.maxUploadable !== -1 &&
                                                        requirement?.documents?.length >= requirement?.maxUploadable
                                                    }
                                                    text={t("uploadRequirement")}
                                                    extensionsAllowed={extensionsAllowed}
                                                    onClick={() => handleUploadDocument("document")}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )
                            ) : (
                                <Button
                                    size="xs"
                                    fontSize="sm"
                                    fontWeight="medium"
                                    colorScheme={"teal"}
                                    rightIcon={<AiOutlineUpload size={16} />}
                                    onClick={() => handleUploadDocument("annex")}
                                >
                                    {t("uploadAnnex")}
                                </Button>
                            )}
                        </div>
                    </div>
                    <div
                        className={`px-2 py-4  ${disabledReq || disabled || !enabledSwitch ? "bg-slate-400 bg-opacity-65 " : ""
                            }  flex flex-col h-[128px] overflow-y-auto gap-4 border-b ${hasDocuments ? "" : " justify-center grow"}`}
                    >
                        {/* doc card */}
                        {showDocuments && (
                            <>
                                {hasDocuments ? (
                                    matchedDocsByDate?.map((doc) => (
                                        <>
                                            <BiddingDocumentCard
                                                key={doc.uuid}
                                                doc={doc}
                                                requirement={requirement}
                                                isSignaturePdf={isPDFType}
                                                isReviewFlowType={isReviewFlowType}
                                                onOpenDoc={handleOpenDocClick}
                                                onDeleteDoc={handleDeleteDocClick}
                                                t={t}
                                            />
                                        </>
                                    ))
                                ) : (
                                    <div className="w-full p-4 flex items-center justify-center grow">
                                        <p className="text-gray-600">No hay documentos subidos</p>
                                    </div>
                                )}
                            </>
                        )}

                        {showAnnexes && (
                            <>
                                {hasAnnexes ? (
                                    matchedAnnexsByDate?.map((doc) => (
                                        <BiddingDocumentCard
                                            key={doc.uuid}
                                            doc={doc}
                                            requirement={requirement}
                                            isSignaturePdf={false}
                                            isReviewFlowType={isReviewFlowType}
                                            onOpenDoc={handleOpenDocClick}
                                            onDeleteDoc={handleDeleteDocClick}
                                            t={t}
                                        />
                                    ))
                                ) : (
                                    <div className="w-full p-4 flex items-center justify-center grow">
                                        <p className="text-gray-600">No hay anexos subidos</p>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                    {disabledReq || disabled || !enabledSwitch ? (
                        ""
                    ) : (
                        <div className="">
                            <Button
                                size="xs"
                                fontSize="xs"
                                fontWeight="medium"
                                rounded={"none"}
                                w="50%"
                                colorScheme={"brand"}
                                variant={showDocuments ? "solid" : "ghost"}
                                onClick={setShowAnnexes.off}
                            >
                                Documentos
                            </Button>
                            <Button
                                size="xs"
                                fontSize="xs"
                                fontWeight="medium"
                                rounded={"none"}
                                w="50%"
                                colorScheme={"brand"}
                                variant={showAnnexes ? "solid" : "ghost"}
                                onClick={setShowAnnexes.on}
                            >
                                Anexos
                            </Button>
                        </div>
                    )}
                </div>
                {Boolean(selectedDoc) && (
                    <PdfViewerFull
                        docVaultId={selectedDoc?.fileId}
                        onClose={() => setSelectedDoc(null)}
                        onDownload={() => handleDownload(selectedDoc?.fileId, selectedDoc?.name)}
                    />
                )}
                <RoadMapViewModal isOpen={showRoadmap} onClose={() => setShowRoadmap(false)} />
            </>
        )
    } else {
        return null;
    }

};

export default RequirementCard;

const SCMCaseButton = ({ disabled = false, onClick, name }) => {
    return (
        <Button disabled={disabled} size="xs" fontSize="sm" fontWeight="medium" colorScheme={"teal"} onClick={onClick}>
            {name}
        </Button>
    );
};

const DocumentGenearlInfo = ({ disabled = false, onClick, name }) => {
    return (
        <Button disabled={disabled} size="xs" fontSize="sm" fontWeight="medium" colorScheme={"teal"} onClick={onClick}>
            {name}
        </Button>
    );
};
