import { useRef } from 'react';
import './KlausChatBubble.css'; // Aquí puedes agregar el CSS
import KlausChatContainer from './KlausChatContainer';
import { useChatHistory } from 'src/store/KlausChatState';
import { SagaChatPlugin } from 'saga-react-widget';
import { Box, Modal } from '@chakra-ui/react';

const KlausChat = () => {
    const chatState = useChatHistory((s) => s);
    const chatRef = useRef<HTMLDivElement | null>(null); // Ref para el contenedor del chat
    const authUser = JSON.parse(sessionStorage.getItem("authUser") || null || '');

    return (
        <div>
            {chatState.chatCompany === 'radamantis' && chatState.chatOpen ? (
                <div >
                <Modal
                    isOpen={chatState.chatOpen}
                    onClose={() => chatState.changeChatState(false)}
                >

                    <Box className="chat-window" ref={chatRef}>
                    <button
                        style={{backgroundImage: `${chatState.chatDesign}`, color: 'white'}}
                        onClick={() => {chatState.changeChatState(false)}}>
                        Salir de Saga
                    </button>
                        <SagaChatPlugin
                            aiApi="https://saga-ai.axeleratum.com"
                            chatApi="https://saga-chat.axeleratum.com"
                            folderId={"6733f204a5e86131a4647c4b"} 
                            token={authUser.state?.accessToken} 
                        />
                    </Box>
                </Modal>
                </div>
            ) : (
                <>
                    {chatState.chatOpen && (
                        <div className="chat-window" ref={chatRef}>
                            <div
                                className={
                                    chatState.chatCompany === 'klaus'
                                        ? 'chat-header-klaus'
                                        : 'chat-header-radamantis'
                                }
                            >
                                <h4>SAGA</h4>
                                <button onClick={() => chatState.changeChatState(false)}>
                                    Cerrar
                                </button>
                            </div>
                            <div className="chat-body">
                                <KlausChatContainer />
                            </div>
                        </div>
                    )}
                    {chatState.chatOpenFromPDF && (
                        <div className="chat-window">
                            <div
                                className={
                                    chatState.chatCompany === 'klaus'
                                        ? 'chat-header-klaus'
                                        : 'chat-header-radamantis'
                                }
                            >
                                <h4>SAGA</h4>
                                <button onClick={() => chatState.changeChatOpenFromPDF(false)}>
                                    Cerrar
                                </button>
                            </div>
                            <div className="chat-body">
                                <KlausChatContainer />
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default KlausChat;
