import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import CommonModal from "../../CommonModal";
import {
    Accordion,
    Button,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverFooter,
    PopoverHeader,
} from "@chakra-ui/react";
import AccordionItemBrand from "../../AccordionItemBrand";
import { useEffect, useState } from "react";
import useCustomToast from "src/hooks/useCustomToast";
import { ListItem, UnorderedList } from "@chakra-ui/react";
import { AiOutlineLine } from "react-icons/ai";
import useBiddingTypeState from "src/store/biddings/biddingTypesState";
import { BiPlus } from "react-icons/bi";
import React from "react";

type Props = {
    isOpen: boolean;
    onClose: (...args: any) => any;
    data?: any;
};

const ExpedientModal = ({ isOpen, onClose, data }: Props) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const toast = useCustomToast();
    const hasDataGroups = data?.groups?.dataGroups?.length > 0;
    const [tempDataGroup, setTempDataGroup] = useState(data?.groups?.dataGroups);
    const [templatesList, setListTemplates] = useState(data?.groups?.templatesList);
    const initialFocusRef = React.useRef();

    //const [tempDataGroup, setTempDataGroup] = useState();

    const { getAllDataGroupByTemplateIdArray } = useBiddingTypeState();

    useEffect(() => {
        getAllDataGroupByTemplateIdArray(data?.arrayStages);
    }, [data.arrayStages]);

    async function handleSave() {
        const groupFilterADD = data?.groups?.dataGroups.filter((item) => item.remove === true || item.remove === undefined);

        let expData = [];
        const output = groupFilterADD.map((element) => {
            const typesObject = element.types[0];
            const newTypesObject = {
                idName: typesObject.idName,
                name: typesObject.name,
                type: typesObject.type,
                valor: typesObject.valor ? typesObject.valor : "",
            };
            return {
                idName: element.idName,
                name: element.name,
                types: [newTypesObject],
            };
        });

        expData.push(output);
        toast.success("Expediente único guardado");
        data.propsAction(groupFilterADD);
        onClose();
    }

    function addDataGroup(e, group) {
        e.preventDefault();
        const updatedJson = tempDataGroup.concat(group);
        setTempDataGroup(updatedJson);
        const groupReq = data?.groups?.dataGroups.find((item) => item.name === group.name);
        groupReq.remove = true;
    }

    function removeDataGroup(e, group) {
        e.preventDefault();
        const updatedGroup = tempDataGroup.filter((item) => item.name !== group.name);
        setTempDataGroup(updatedGroup);
        const groupReq = data?.groups?.dataGroups.find((item) => item.name === group.name);
        groupReq.remove = false;
    }

    return (
        <CommonModal size="6xl" isOpen={isOpen} onClose={onClose}>
            <div className="w-full flex flex-col h-[80vh] relative">
                <div className="w-full flex items-end justify-between gap-6 sticky top-0">
                    <div className="ml-4">
                        <h2 className="title-3">Datos del expediente</h2>
                        <h3 className="text-dark">
                            Los datos del expediente se conforman de los grupos de datos configurados en las plantillas, que estén asociadas para generar un documento dinámico.
                        </h3>
                    </div>
                    <div className="mr-6">
                        <Button rounded={"full"} disabled={!hasDataGroups} onClick={handleSave}>
                            Guardar
                        </Button>
                    </div>
                </div>
                {hasDataGroups ? (
                    <div className="px-2 mt-4 overflow-auto">
                        <Accordion allowMultiple>
                            {data?.groups?.dataGroups?.map((dg, idx) => (
                                <AccordionItemBrand
                                    key={idx}
                                    title={dg.name}
                                    subTitle={
                                        <div className="content-center">
                                            {data?.groups?.templatesList.map((group, ids) => {
                                                if (idx === ids) {
                                                    return (
                                                        <div key={ids}>
                                                            <Popover
                                                                initialFocusRef={initialFocusRef}
                                                                placement="bottom"
                                                                closeOnBlur={false}
                                                            >
                                                                <Button
                                                                    size="xs"
                                                                    fontSize="xs"
                                                                    fontWeight="medium"
                                                                    colorScheme={"blue"}
                                                                >
                                                                    Plantillas Asociadas
                                                                </Button>
                                                                <PopoverContent color="white" bg="blue.800" borderColor="blue.800">
                                                                    <PopoverHeader pt={4} fontWeight="bold" border="0">
                                                                        {""}
                                                                    </PopoverHeader>
                                                                    <PopoverArrow />
                                                                    <PopoverCloseButton />
                                                                    <PopoverBody>
                                                                        {group.map((template, index) => (
                                                                            <div key={index}>
                                                                                <ul>
                                                                                    {template.split(",").map((value, i) => (
                                                                                        <li key={i}>{value.trim()}</li>
                                                                                    ))}
                                                                                </ul>
                                                                            </div>
                                                                        ))}
                                                                    </PopoverBody>
                                                                    <PopoverFooter
                                                                        border="0"
                                                                        display="flex"
                                                                        alignItems="center"
                                                                        justifyContent="space-between"
                                                                        pb={4}
                                                                    ></PopoverFooter>
                                                                </PopoverContent>
                                                            </Popover>
                                                        </div>
                                                    );
                                                } else {
                                                    return null;
                                                }
                                            })}
                                        </div>
                                    }
                                    actions={
                                        <div>
                                            {dg.remove === false ? (
                                                <Button
                                                    size="xs"
                                                    fontSize="xs"
                                                    fontWeight="medium"
                                                    colorScheme={"teal"}
                                                    leftIcon={<BiPlus size={16} />}
                                                    onClick={(e) => addDataGroup(e, dg)}
                                                >
                                                    {"Agregar grupo de datos"}
                                                </Button>
                                            ) : (
                                                <Button
                                                    size="xs"
                                                    fontSize="xs"
                                                    fontWeight="medium"
                                                    colorScheme={"red"}
                                                    leftIcon={<AiOutlineLine size={16} />}
                                                    onClick={(e) => removeDataGroup(e, dg)}
                                                >
                                                    {"Quitar grupo de datos"}
                                                </Button>
                                            )}
                                        </div>
                                    }
                                >
                                    <div className="px-6 mt-4 overflow-auto">
                                        {dg.types.map((type, idx) => (
                                            <UnorderedList>
                                                <ListItem> {type.name}</ListItem>
                                            </UnorderedList>
                                        ))}
                                    </div>
                                </AccordionItemBrand>
                            ))}
                        </Accordion>
                    </div>
                ) : (
                    <div className="flex flex-col items-center justify-center h-full">
                        <h3 className="text-dark">No hay grupos de datos para mostrar</h3>
                    </div>
                )}
            </div>
        </CommonModal>
    );
};

export default ExpedientModal;
