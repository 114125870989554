import { IoClose, IoTrashOutline } from "react-icons/io5";
import CommonTh from "../common/table/CommonTh";
import useModalState from "../../store/modalsState";
import DatePickerTh from "../common/table/DatePickerTh";
import SelectTh from "../common/table/SelectTh";
import { useNavigate } from "react-router-dom";
import { DataGroup } from "../../models/DataGroup.models";
import { formatDate } from "../../lib/utils";
import usePagination from "../../hooks/usePagination";
import { Tooltip } from "@chakra-ui/react";
import { MouseEvent, useMemo } from "react";
import useDataGroupsState from "../../store/dataGroupsState";
import useCustomToast from "../../hooks/useCustomToast";
import { GENERIC_DATAGROUP_TYPE } from "../../lib/constants";
import useDataGroupsFilter from "../../hooks/table-filters/useDataGroupsFilter";
import LinkButton from "../common/buttons/LinkButton";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import DottedSubtitle from "../common/DottedSubtitle";
import Paginator from "../common/Paginator";

const TableRow = ({ dataGroup }: { dataGroup: DataGroup }) => {
    const navigate = useNavigate();
    const toast = useCustomToast();
    const setDeleteDataGroupModal = useModalState((s) => s.setDeleteCommonModal);
    const setDeleteDataGroup = useDataGroupsState((s) => s.deleteDataGroup);
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];

    async function onDelete() {
        const dtDeleted = await setDeleteDataGroup(dataGroup.id);
        if (dtDeleted) {
            toast.success(t("deletedDataGroupSuccess"));
        } else {
            toast.error(t("deletedDataGroupError"));
        }
    }

    function handleDelete(e: MouseEvent) {
        e.stopPropagation();
        setDeleteDataGroupModal({ show: true, data: { text1: t("dataGroupWillBeDeleted"), text2: dataGroup.name, onDelete } });
    }

    return (
        <tr
            className="border-b hover:bg-slate-100 transition-colors duration-200 cursor-pointer"
            onClick={() => navigate(`${dataGroup.id}`)}
        >
            {/* <td className="w-4 px-2 py-3" onClick={(e) => e.stopPropagation()}>
                <div className="flex items-center">
                    <Checkbox />
                </div>
            </td> */}
            <td className="px-2 py-3">{dataGroup.name}</td>
            <td className="px-2 py-3">{dataGroup.userName}</td>
            <td className="px-2 py-3">{formatDate(new Date(dataGroup?.createdDate || "01-01-2022"))}</td>
            <td className="px-2 py-3 capitalize">{dataGroup.status === GENERIC_DATAGROUP_TYPE ? t("generic") : dataGroup.status}</td>
            <td className="px-2 py-3">{formatDate(new Date(dataGroup?.lastModifiedDate || "01-01-2022"))}</td>
            <td className="px-2 py-3">
                <div className="w-full xl:w-7/12 flex justify-center gap-6">
                    <Tooltip label={t("delete")} fontSize="12px">
                        <button onClick={handleDelete}>
                            <IoTrashOutline size={25} />
                        </button>
                    </Tooltip>
                </div>
            </td>
        </tr>
    );
};

const DataGroupsTable = ({ dataGroups }: { dataGroups: DataGroup[] }) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const { filteredDataGroups, handleDateFilter, isFilterActive, clearFilters, handleTypeFilter, handleSearchFilter } =
        useDataGroupsFilter(dataGroups);

    const {
        currentResutls: paginatedDataGroups,
        currentPage,
        maxPages,
        prev,
        next,
        setPage,
    } = usePagination<DataGroup>({ data: filteredDataGroups, itemsPerPage: 6 });

    const dataGroupsTypes = useMemo(() => {
        const types = new Set<string>();
        dataGroups.forEach((dataGroup) => {
            types.add(dataGroup.status);
        });
        return Array.from(types).sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1));
    }, [dataGroups]);
    

    return (
        <>
            <div className="w-full flex justify-between items-center">
                <DottedSubtitle
                    text={t("myDataGroups")}
                    withSearch
                    onSearch={handleSearchFilter}
                    beforeSearchElement={
                        isFilterActive && (
                            <LinkButton onClick={clearFilters} color="red.400">
                                <IoClose size={20} /> {t("cleanFilters")}
                            </LinkButton>
                        )
                    }
                />
            </div>
            <div>
                <table className="w-full text-sm text-left text-dark-2">
                    <thead className="text-base text-brand-500 font-medium border-b">
                        <tr>
                            {/* <th scope="col" className="px-2">
                                <div className="flex items-center">
                                    <Checkbox />
                                </div>
                            </th> */}
                            <th scope="col" className="px-2 py-4">
                                <CommonTh text={t("name")} onClick={() => {}} />
                            </th>
                            <th scope="col" className="px-2 py-4">
                                <CommonTh text={t("author")} onClick={() => {}} />
                            </th>
                            <th scope="col" className="px-2 py-4">
                                <DatePickerTh text={t("date")} onChange={(args: any) => handleDateFilter(args, "createdDate")} />
                            </th>
                            <th scope="col" className="px-2 py-4">
                                <SelectTh
                                    text={t("type")}
                                    options={dataGroupsTypes.map((s) => ({ label: s, value: s }))}
                                    onSelect={handleTypeFilter}
                                />
                            </th>
                            <th scope="col" className="px-2 py-4">
                                <DatePickerTh
                                    text={t("lastVersion")}
                                    onChange={(args: any) => handleDateFilter(args, "lastModifiedDate")}
                                />
                            </th>
                            <th scope="col" className="px-2 py-4">
                                {t("actions")}
                            </th>
                        </tr>
                        <tr></tr>
                    </thead>
                    <tbody>
                        {paginatedDataGroups?.map((item, idx) => (
                            <TableRow key={item.id} dataGroup={item} />
                        ))}
                    </tbody>
                </table>
                <div className="flex justify-between items-center">
                    {paginatedDataGroups?.length ? (
                        <div className="w-full py-6">
                            <Paginator currentPage={currentPage} maxPages={maxPages} onPrev={prev} onNext={next} onSetPage={setPage} />
                        </div>
                    ) : (
                        <div className="w-full text-center p-2 text-dark">{t("notElementsFound")}</div>
                    )}
                </div>
            </div>
        </>
    );
};
export default DataGroupsTable;
