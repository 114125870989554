import create  from 'zustand';

// Definimos el store usando zustand
interface ArrayStore {
  chatDesign: string;
  chatCompany: string;
  chatOpen: boolean;
  chatOpenFromPDF: boolean;
  idFolder: string;
  chatId: string; // Puedes cambiar el tipo según lo que quieras guardar
  changeChatState: (value: boolean) => void;
  changeChatOpenFromPDF: (value: boolean) => void;
  setChatId: (id: string) => void;
  setChatDesign: (design: string) => void;
  setChatCompany: (company: string) => void;
 }

export const useChatHistory = create<ArrayStore>((set) => ({
  chatOpen: false,
  chatOpenFromPDF: false,
  chatId: null,
  chatDesign: '',
  idFolder: '',
  chatCompany: '',
  changeChatState: (value) => set(() => {
    return { chatOpen: value};
  }),
  changeChatOpenFromPDF: (value: boolean) => set(() => {
    return { chatOpenFromPDF: value };
  }),
  setChatId: (id: string) => set({ chatId: id }),
  setIdFolder: (id: string) => set({ idFolder: id }),
  setChatCompany: (company: string) => set({ chatCompany: company }),
  setChatDesign: (design: string) => set({chatDesign: design}),
}));
