import { useConst, useDisclosure } from "@chakra-ui/hooks";
import { Input } from "@chakra-ui/input";
import { Popover, PopoverArrow, PopoverCloseButton, PopoverContent } from "@chakra-ui/popover";
import { useRef, useState } from "react";
import { GoTriangleDown, GoTriangleUp } from "react-icons/go";
import FocusLock from "react-focus-lock";
import { nanoid } from "nanoid";

const Form = ({ id, firstFieldRef, onChange }: { id: string; firstFieldRef: any; onChange: any }) => {
    const [from, setFrom] = useState("");
    const [to, setTo] = useState("");

    function handleFromChange(e: any) {
        setFrom(e.target.value);
        onChange({ from: e.target.value, to });
    }

    function handleToChange(e: any) {
        setTo(e.target.value);
        onChange({ from, to: e.target.value });
    }

    return (
        <div className="flex flex-col">
            <div>
                <label ref={firstFieldRef} htmlFor={`from_date_${id}`} className="text-sm font-light text-dark">
                    Desde:
                </label>
                <Input bg="white" size="sm" rounded="none" id={`from_date_${id}`} type="date" onChange={handleFromChange} />
            </div>
            <div className="flex flex-col mt-2">
                <label htmlFor={`to_date_${id}`} className="text-sm font-light text-dark">
                    Hasta:
                </label>
                <Input bg="white" size="sm" rounded="none" id={`to_date_${id}`} type="date" onChange={handleToChange} />
            </div>
        </div>
    );
};

const DatePickerTh = ({ text, onChange }: { text: string; onChange: (args: { from: any; to: any }) => any }) => {
    const { onOpen, onClose, isOpen } = useDisclosure();
    const firstFieldRef = useRef(null);
    const uid = useConst(() => nanoid(5));

    return (
        <Popover
            key={uid}
            isOpen={isOpen}
            initialFocusRef={firstFieldRef}
            onOpen={onOpen}
            onClose={onClose}
            placement="bottom"
            closeOnBlur={true}
            flip={true}
        >
            <button
                onClick={isOpen ? onClose : onOpen}
                className="flex items-center font-bold"
            >
                <span className="mr-1">{text}</span>
                {!isOpen ? <GoTriangleDown size={14} /> : <GoTriangleUp size={14} />}
            </button>
            <PopoverContent p={5} maxW="16rem" bg="gray.50">
                <FocusLock returnFocus persistentFocus={false}>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <Form id={uid} firstFieldRef={firstFieldRef} onChange={onChange} />
                </FocusLock>
            </PopoverContent>
        </Popover>
    );
};

export default DatePickerTh;
