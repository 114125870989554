import { Menu, MenuButton, MenuDivider, MenuItem, MenuItemOption, MenuList, MenuOptionGroup, Portal, useTheme } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import { BiChevronDown } from "react-icons/bi";
import { FiLogOut } from "react-icons/fi";
import useAuthState from "../store/authState";
import UserOutlineIcon from "./icons/UserOutlineIcon";
import saga_logo from 'src/assets/images/saga_logo.png';
import useGlobalState from "src/store/globalState";
import useModalState from "src/store/modalsState";
import { useChatHistory } from "src/store/KlausChatState";
import { Avatar, AvatarBadge, AvatarGroup } from '@chakra-ui/react'

const UserButtonMenu = ({ color = "brand" }: { color?: "brand" | "white" }) => {
    const theme = useTheme();
    const { i18n } = useTranslation("global");
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const logout = useAuthState((state) => state.logout);
    const { userCompanyInfo } = useGlobalState();
    const state = useModalState((s) => s);
    const chatState = useChatHistory((s) => s);

    const [currentLang, setCurrentLang] = useState(localStorage.getItem("lang") ?? "es");

    const handleChangeLang = (lang: "es" | "en" | "esAmericaMovil") => {
        setCurrentLang(lang);
        localStorage.setItem("lang", lang);
        i18n.changeLanguage(lang);
    };
    const { userName } = useAuthState((s) => s);

    const selectedColor = color === "brand" ? theme.colors.brand[600] : theme.colors.white;

    const normalizedCompany = userCompanyInfo?.name
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/\s/g, "")
        .toLocaleLowerCase();

    return (
        <>
            {chatState.chatCompany === 'radamantis' && state?.sagaChatKlaus && (
                <div 
                    style={{cursor: 'pointer', backgroundImage: `${chatState.chatDesign}`, borderRadius: '50%', marginBottom: '3%'}} 
                    onClick={() => {
                        if (!chatState.chatOpen) {
                            chatState.changeChatState(true);
                        }
                    }}>
                    <img src={saga_logo} />
                </div>
            )}
            <Menu closeOnSelect={false}>
                <MenuButton style={{ all: "unset", display: "block", cursor: "pointer", paddingLeft: "1rem", borderLeft: "1px solid gray" }}>
                    <button className="flex items-center gap-2">
                        <Avatar name={userName} />
                        <BiChevronDown size={20} color={selectedColor} />
                    </button>
                </MenuButton>
                <Portal>
                    <MenuList shadow={"2xl"} zIndex="modal" style={{ minWidth: "unset" }}>
                        <div className="w-[200px] px-2 max-h-[70vh] overflow-y-auto relative">
                            <div className="w-full flex justify-between items-center text-lg font-medium text-brand-600 border-b p-2 sticky top-0 bg-white">
                                <div>{userName}</div>
                            </div>

                            <MenuDivider />
                            {/**<MenuItem style={{ all: "unset", width: "100%" }}>
                                <div className="w-full p-2 flex gap-2 items-center justify-between cursor-pointer hover:bg-brand-50 text-gray-600">
                                    <p>Mi cuenta</p>
                                </div>
                            </MenuItem> */}
                            <MenuItem style={{ all: "unset", width: "100%" }} onClick={() => logout()}>
                                <div className="w-full p-2 flex gap-2 items-center justify-between cursor-pointer hover:bg-brand-50 text-red-400">
                                    <p>{t("logout")}</p>
                                    <FiLogOut size={20} color="inherit" />
                                </div>
                            </MenuItem>
                            <MenuDivider />
                            <MenuOptionGroup value={currentLang} title={t("language")} type="checkbox">
                                <MenuItemOption
                                    onClick={() => handleChangeLang(normalizedCompany === "americamovil" ? "esAmericaMovil" : "es")}
                                    value="es"
                                >
                                    Español
                                </MenuItemOption>
                                <MenuItemOption onClick={() => handleChangeLang("en")} value="en">
                                    English
                                </MenuItemOption>
                            </MenuOptionGroup>
                        </div>
                    </MenuList>
                </Portal>
            </Menu>
        </>
    );
};
export default UserButtonMenu;
